import React, { useContext } from "react";

import { graphql } from "gatsby";

import { PaginationLinks } from "../../components/pagination-links";
import { CategoryItems } from "../../containers/category-items";
import { ProductFiltersContext } from "../../context-providers/product-filters-context";
import { getProductFiltersDefaultValues } from "../../helpers/get-product-filters";
import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";

import "./category.scss";

// TODO:  Delete this after adding appsync
// const timeout = (ms) => {
//     return new Promise(resolve => setTimeout(resolve, ms));
// };

const CategoryTemplate = ({ pageContext, data }) => {
    const {
        rating1ProductFilterValue,
        rating2ProductFilterValue,
        rating3ProductFilterValue,
        orderByProductFilterValue,
        minMaxProductFilterValue,
    } = useContext(ProductFiltersContext);

    const pageDetails = {
        category: pageContext.category,
        subCategory: pageContext.subCategory,
        currentLanguageRegion: pageContext.currentLanguageRegion,
        title: pageContext.title,
        meta: pageContext.meta,
        script: pageContext.script,
        urlPath: pageContext.urlPath,
        currentPage: pageContext.currentPage,
        numberOfPages: pageContext.numberOfPages,
    };

    const productFiltersDefaultValues = getProductFiltersDefaultValues(data.productFiltersDefaultValues);

    const defaultProducts = [data.product1, data.product2, data.product3, data.product4, data.product5, data.product6, data.product7, data.product8, data.product9, data.product10].filter(e => e);

    return (
        <DefaultLayout pageDetails={pageDetails} >
            <ContentLayout
                affiliateDisclosure={true}
                category={data.category}
                subCategory={data.subCategory}
                filtersEnabled={true}
                title={pageContext.title}
                highestPrice={pageContext.highestPrice}
            >
                <CategoryItems
                    {...productFiltersDefaultValues}
                    rating1ProductFilterValue={rating1ProductFilterValue}
                    rating2ProductFilterValue={rating2ProductFilterValue}
                    rating3ProductFilterValue={rating3ProductFilterValue}
                    orderByProductFilterValue={orderByProductFilterValue}
                    minMaxProductFilterValue={minMaxProductFilterValue}
                    currentPage={pageContext.currentPage}
                    productsPerPage={pageContext.site && pageContext.site.siteMetadata && pageContext.site.siteMetadata.productsPerPage}
                    categoryId={data.categoryId}
                    subCategoryId={data.subCategoryId}
                    defaultProducts={defaultProducts}
                />
                <PaginationLinks
                    currentPage={pageContext.currentPage}
                    numPages={pageContext.numPages}
                    categorySlug={pageContext.categorySlug}
                    subCategorySlug={pageContext.subCategorySlug}
                />
            </ContentLayout>
        </DefaultLayout>
    );

};

export const CategoryQueryFragment = graphql`
    fragment CategoryProductFields on Product {
        PK
        SK
        givenName
        formattedName
        variantName

        slug
        noVariantSlug
        noBrandSlug
        variantSlug

        categoryIds
        subCategoryIds

        brand {
            formattedName
        }

        imageKeys
        # images {
        #     childImageSharp {
        #         gatsbyImageData(
        #             width: 400
        #             placeholder: BLURRED
        #             formats: [AUTO, WEBP]
        #         )
        #     }
        # }
        featureSlugs
        rating1
        rating2
        rating3
        # ratingsTotal
        rating1Heading
        rating2Heading
        rating3Heading
        updatedAt
    }
`;


export const CategoryQueryPriceFragment = graphql`
    fragment CategoryProductPriceFields on Product {
        variantPrices {
            gb @include(if: $includeRegionPricesGB) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            au @include(if: $includeRegionPricesAU) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            us @include(if: $includeRegionPricesUS) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            ca @include(if: $includeRegionPricesCA) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            nz @include(if: $includeRegionPricesNZ) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
        }
        variantPriceMetadata {
            gb @include(if: $includeRegionPricesGB) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            au @include(if: $includeRegionPricesAU) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            us @include(if: $includeRegionPricesUS) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            ca @include(if: $includeRegionPricesCA) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            nz @include(if: $includeRegionPricesNZ) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
        }
    }
`;

export const query = graphql`
  query CategoryTemplate(
        $categoryId: Int!,
        $subCategoryId: Int,
        $hasSubCategoryId: Boolean!,
        $includeProduct1: Boolean!,
        $includeProduct2: Boolean!,
        $includeProduct3: Boolean!,
        $includeProduct4: Boolean!,
        $includeProduct5: Boolean!,
        $includeProduct6: Boolean!,
        $includeProduct7: Boolean!,
        $includeProduct8: Boolean!,
        $includeProduct9: Boolean!,
        $includeProduct10: Boolean!,
        $includeRegionPricesGB: Boolean!,
        $includeRegionPricesAU: Boolean!,
        $includeRegionPricesUS: Boolean!,
        $includeRegionPricesCA: Boolean!,
        $includeRegionPricesNZ: Boolean!,
        $productPK1: String,
        $productPK2: String,
        $productPK3: String,
        $productPK4: String,
        $productPK5: String,
        $productPK6: String,
        $productPK7: String,
        $productPK8: String,
        $productPK9: String,
        $productPK10: String,
        $productSK1: String,
        $productSK2: String,
        $productSK3: String,
        $productSK4: String,
        $productSK5: String,
        $productSK6: String,
        $productSK7: String,
        $productSK8: String,
        $productSK9: String,
        $productSK10: String,
    ) {
        site {
            siteMetadata {
                productsPerPage
            }
        }
        productFiltersDefaultValues: allNewProductFilter {
            edges {
                node {
                    defaultValue
                    defaultValues
                    defaultValueForOrder {
                        stateValue
                    }
                }
            }
        }
        category: category(categoryId: {eq: $categoryId}) {
            categoryId
            formattedName
            heading
            name
            priority
            slug
        }
        subCategory: subCategory(subCategoryId: {eq: $subCategoryId}) @include(if: $hasSubCategoryId) {
            subCategoryId
            formattedName
            heading
            name
            priority
            productType
            slug
        }
        product1: product(PK: {eq: $productPK1}, SK: {eq: $productSK1}) @include(if: $includeProduct1) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product2: product(PK: {eq: $productPK2}, SK: {eq: $productSK2}) @include(if: $includeProduct2) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product3: product(PK: {eq: $productPK3}, SK: {eq: $productSK3}) @include(if: $includeProduct3) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product4: product(PK: {eq: $productPK4}, SK: {eq: $productSK4}) @include(if: $includeProduct4) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product5: product(PK: {eq: $productPK5}, SK: {eq: $productSK5}) @include(if: $includeProduct5) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product6: product(PK: {eq: $productPK6}, SK: {eq: $productSK6}) @include(if: $includeProduct6) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product7: product(PK: {eq: $productPK7}, SK: {eq: $productSK7}) @include(if: $includeProduct7) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product8: product(PK: {eq: $productPK8}, SK: {eq: $productSK8}) @include(if: $includeProduct8) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product9: product(PK: {eq: $productPK9}, SK: {eq: $productSK9}) @include(if: $includeProduct9) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
        product10: product(PK: {eq: $productPK10}, SK: {eq: $productSK10}) @include(if: $includeProduct10) {
            ...CategoryProductFields
            ...CategoryProductPriceFields
        }
    }
`;

export default CategoryTemplate;
