import React, { useContext } from "react";

import { Link } from "gatsby";

import { LanguageRegionContext } from "../../context-providers/language-region-context";
import { getPath } from "../../helpers/get-path";

// CSS
import "./pagination-links.scss";

// const areEqual = (prevProps, nextProps) => {
//     console.log("prevProps, nextProps", prevProps, nextProps);

//     return prevProps.productFilters.every((productFilter, index) => {
//         productFilter.value === nextProps.productFilters[index].value;
//     });
// };

export const PaginationLinks = ({ isStaticPages = true, setPageNumber, currentPage, numPages, categorySlug, subCategorySlug, rating1ProductFilter, rating2ProductFilter, rating3ProductFilter, orderByProductFilter, minMaxProductFilter}) => {

    const { languageRegion } = useContext(LanguageRegionContext);
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? undefined : `${(currentPage - 1).toString()}`;
    const nextPage = `${(currentPage + 1).toString()}`;
    const prevPagePath = getPath(languageRegion, undefined, categorySlug, subCategorySlug, rating1ProductFilter, rating2ProductFilter, rating3ProductFilter, orderByProductFilter, minMaxProductFilter, prevPage);
    const nextPagePath = getPath(languageRegion, undefined, categorySlug, subCategorySlug, rating1ProductFilter, rating2ProductFilter, rating3ProductFilter, orderByProductFilter, minMaxProductFilter, nextPage);

    // useEffect(() => {

    // }, [productFilters]);

    if (numPages > 1) {
        if (isStaticPages) {
            return (
                <div className="c-pagination-links">
                    <nav className="g-flex__container c-pagination-links__link-container">
                        {!isFirst && (<Link className="g-flex__item c-pagination-links__link" to={`${prevPagePath}`} rel="prev">← Previous Page</Link>)}
                        {!isLast && (<Link className="g-flex__item c-pagination-links__link" to={`${nextPagePath}`} rel="next">Next Page →</Link>)}
                    </nav>
                </div>
            );
        } else {
            const onClickHandlerPreviousButton = (currentPage) => {
                //console.log('onClickHandlerPreviousButton');

                const currentPageZeroIndexed = currentPage === 0 ? 0 : currentPage - 1;
                setPageNumber(currentPageZeroIndexed - 1);

                const target = document.getElementById("scroll-target");
                target.scrollIntoView();
            };

            const onClickHandlerNextButton = (currentPage) => {
                //console.log('onClickHandlerNextButton');

                const currentPageZeroIndexed = currentPage === 0 ? 0 : currentPage - 1;
                setPageNumber(currentPageZeroIndexed + 1);

                const target = document.getElementById("scroll-target");
                target.scrollIntoView();
            };

            return (
                <div className="c-pagination-links">
                    <nav className="g-flex__container c-pagination-links__link-container">
                        {!isFirst && (<button className="g-flex__item c-pagination-links__link" onClick={() => onClickHandlerPreviousButton(currentPage)}>← Previous Page</button>)}
                        {!isLast && (<button className="g-flex__item c-pagination-links__link" onClick={() => onClickHandlerNextButton(currentPage)}>Next Page →</button>)}
                    </nav>
                </div>
            );
        }
    }

    return (
        <div></div>
    );
};
