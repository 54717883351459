import React, {useState } from "react";

import PropTypes from "prop-types";

import { Prices } from "../../containers/prices";
import { getPath } from "../../helpers/get-path";
// import { CustomerReviewRating } from '../customer-review-rating';
import { Image } from "../image";
import { RatingBar } from "../rating-bar";
import { SubVariantFilter} from "../sub-variant-filter";
import { VerdictListing } from "../verdict-listing";
import "./product-listing.scss";

export const ProductListing = (props) => {
    const [currentSubVariant, setCurrentSubVariant] = useState("all");

    const handleSubVariantClick = (newSubVariant) => {
        console.log("newSubVariant", newSubVariant);

        setCurrentSubVariant(newSubVariant);
    };

    const categorySlug = props.category && props.category.slug;
    const subCategorySlug = props.subCategory && props.subCategory.slug;

    const productPath = getPath(props.languageRegion, props.product.slug, categorySlug, subCategorySlug);
    const listItemClassName = props.containerClasses ? props.containerClasses : "";

    return (
        <li className={`c-product-listing ${listItemClassName}`} key={`compare-${props.product.slug}`}>
            <article className="c-compare-item">
                <div className="c-compare-item__container">
                    <div className="c-product-item g-grid__container--product-listing">
                        <div className="g-grid__item g-grid__item--product-listing-title">
                            <h2 className="g-flex__container g-flex__container--column-always c-product-item__title">
                                <span className="g-flex__item primary-font c-product-item__title-name">{`${props.product.brand.formattedName} ${props.product.formattedName} `}</span>
                                <span className="g-flex__item c-product-item__title-variant-name">{`in ${props.product.variantName}`}</span>
                            </h2>
                        </div>
                        <div className="g-grid__item g-grid__item--sub-variant-filter">
                            <SubVariantFilter componentId={`${props.product.slug}-prices`} variantPrices={props.product.variantPrices} region={props.languageRegion.slice(-2)} currentSubVariant={currentSubVariant} handleSubVariantClick={handleSubVariantClick} />
                        </div>
                        <div className="g-grid__item g-grid__item--product-listing-prices">
                            <Prices variantPrices={props.product.variantPrices} priceMetadata={props.product.variantPriceMetadata[props.languageRegion.slice(-2)]} region={props.languageRegion.slice(-2)} subVariantName={currentSubVariant} internalLink={false} showDealButton={true} priceGuide={true} />
                        </div>
                        <div className="g-grid__item g-grid__item--product-listing-ratings">
                            <div className="g-grid__container--stack">
                                <div className="g-grid__item g-grid__item--stack-2">
                                    {/* <CustomerReviewRating
                                        customerReviewRating={props.product.brand.customerReviewRating}
                                        customerReviewRatingLength={props.product.brand.customerReviewRatingLength}
                                        customerReviewRatingUrl={props.product.brand.customerReviewRatingUrl}
                                    /> */}
                                </div>
                                <div className="g-grid__item g-grid__item--stack-3">
                                    <RatingBar headingLevel={3} heading={props.product.rating1Heading} rating={props.product.rating1 || 0} />
                                    <RatingBar headingLevel={3} heading={props.product.rating2Heading} rating={props.product.rating2 || 0} />
                                    <RatingBar headingLevel={3} heading={props.product.rating3Heading} rating={props.product.rating3 || 0} />
                                </div>
                            </div>
                        </div>

                        <div className="g-grid__item g-grid__item--product-listing-image">
                            <Image
                                imgClassName="c-product-item__image"
                                image={props.product.images && props.product.images[0]}
                                alt=""
                            />
                        </div>
                        <div className="g-grid__item g-grid__item--product-listing-verdict">
                            <VerdictListing
                                ratingBarTitle="Our Verdict"
                                rating={props.product.ratingsTotal || 0}
                                productPath={productPath} />
                            {/* <div className="g-grid__container--deal-button">
                                <Prices productVariants={productVariants} size="" internalLink={false} dealButtonOnly={true} />
                            </div> */}
                        </div>
                        {/* <div className="g-grid__item g-grid__item--compare-item-4">
                <Prices productVariants={productVariants} size="" dealButtonOnly={true} />
              </div> */}
                        <div className="g-grid__item g-grid__item--spacer"></div>

                    </div>
                </div>
            </article>
        </li>
    );
};

// PropTypes
ProductListing.propTypes = {
    containerClasses: PropTypes.string,
    languageRegion: PropTypes.string.isRequired,
    category: PropTypes.shape({
        data: PropTypes.shape({
            slug: PropTypes.string.isRequired,
        })
    }).isRequired,
    subCategory: PropTypes.shape({
        data: PropTypes.shape({
            slug: PropTypes.string.isRequired,
        })
    }),
    // Product
    product: PropTypes.shape({
        formattedName: PropTypes.string.isRequired,
        givenName: PropTypes.string.isRequired, // Required to get slug
        slug: PropTypes.string.isRequired,
        images: PropTypes.array,
        rating1: PropTypes.number.isRequired,
        rating2: PropTypes.number.isRequired,
        rating3: PropTypes.number.isRequired,
        ratingsTotal: PropTypes.number.isRequired,
        rating1Heading: PropTypes.string.isRequired,
        rating2Heading: PropTypes.string.isRequired,
        rating3Heading: PropTypes.string.isRequired,
        brand:PropTypes.shape({
            formattedName: PropTypes.string.isRequired,
        }),
        featureSlugs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, // Required to get ratings
        variantPrices: PropTypes.shape({
            gb: PropTypes.arrayOf(PropTypes.shape({
                supplierId: PropTypes.number.isRequired,
                value: PropTypes.number.isRequired,
                subVariantName: PropTypes.string.isRequired,
                region: PropTypes.string.isRequired,
                productUrl: PropTypes.string.isRequired,
                supplier: PropTypes.shape({
                    supplierId: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    formattedName: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    deliveryFee: PropTypes.number.isRequired,
                    deliveryFreeOver: PropTypes.number.isRequired,
                }),
                updatedAt: PropTypes.string.isRequired,
            })),
            au: PropTypes.arrayOf(PropTypes.shape({
                supplierId: PropTypes.number.isRequired,
                value: PropTypes.number.isRequired,
                subVariantName: PropTypes.string.isRequired,
                region: PropTypes.string.isRequired,
                productUrl: PropTypes.string.isRequired,
                supplier: PropTypes.shape({
                    supplierId: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    formattedName: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    deliveryFee: PropTypes.number.isRequired,
                    deliveryFreeOver: PropTypes.number.isRequired,
                }),
                updatedAt: PropTypes.string.isRequired,
            })),
            us: PropTypes.arrayOf(PropTypes.shape({
                supplierId: PropTypes.number.isRequired,
                value: PropTypes.number.isRequired,
                subVariantName: PropTypes.string.isRequired,
                region: PropTypes.string.isRequired,
                productUrl: PropTypes.string.isRequired,
                supplier: PropTypes.shape({
                    supplierId: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    formattedName: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    deliveryFee: PropTypes.number.isRequired,
                    deliveryFreeOver: PropTypes.number.isRequired,
                }),
                updatedAt: PropTypes.string.isRequired,
            })),
            ca: PropTypes.arrayOf(PropTypes.shape({
                supplierId: PropTypes.number.isRequired,
                value: PropTypes.number.isRequired,
                subVariantName: PropTypes.string.isRequired,
                region: PropTypes.string.isRequired,
                productUrl: PropTypes.string.isRequired,
                supplier: PropTypes.shape({
                    supplierId: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    formattedName: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    deliveryFee: PropTypes.number.isRequired,
                    deliveryFreeOver: PropTypes.number.isRequired,
                }),
                updatedAt: PropTypes.string.isRequired,
            })),
            nz: PropTypes.arrayOf(PropTypes.shape({
                supplierId: PropTypes.number.isRequired,
                value: PropTypes.number.isRequired,
                subVariantName: PropTypes.string.isRequired,
                region: PropTypes.string.isRequired,
                productUrl: PropTypes.string.isRequired,
                supplier: PropTypes.shape({
                    supplierId: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    formattedName: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    deliveryFee: PropTypes.number.isRequired,
                    deliveryFreeOver: PropTypes.number.isRequired,
                }),
                updatedAt: PropTypes.string.isRequired,
            })),
        }),
    }),
};
