import React, { useContext, useState, useEffect } from "react";

// import { gql, useQuery } from "@apollo/client";

import { ProductListing } from "../../components/product-listing";
import { LanguageRegionContext } from "../../context-providers/language-region-context";

export const CategoryItems = (props) => {
    const { languageRegion } = useContext(LanguageRegionContext);
    const region = languageRegion.slice(-2);

    const [products, setProducts] = useState([]);
    const [productLoadingState, setProductLoadingState] = useState(false);

    const [categoryProducts, setCategoryProducts] = useState([]);
    const [categoryProductsLoadingState, setCategoryProductsLoadingState] = useState(false);

    useEffect(() => {
        if (
            props.rating1ProductFilterValue !== props.rating1DefaultValue ||
            props.rating2ProductFilterValue !== props.rating2DefaultValue ||
            props.rating3ProductFilterValue !== props.rating3DefaultValue ||
            props.minMaxProductFilterValue !== props.minMaxDefaultValue ||
            props.orderByProductFilterValue !== props.orderByDefaultValue
        ) {
            setCategoryProductsLoadingState(true);
            const PK = props.subCategoryId ? `CATEGORY#${region}#${props.categoryId}#${props.subCategoryId}` : `CATEGORY#${region}#${props.categoryId}`;
            const SK = "";

            // QUERY HERE

            // setCategoryProductsLoadingState(loading);
            // setCategoryProducts(data);
        }
    }, [props.currentPage, props.categoryId, props.subCategoryId, region ]);

    useEffect(() => {
        if (
            props.rating1ProductFilterValue !== props.rating1DefaultValue ||
            props.rating2ProductFilterValue !== props.rating2DefaultValue ||
            props.rating3ProductFilterValue !== props.rating3DefaultValue ||
            props.minMaxProductFilterValue !== props.minMaxDefaultValue ||
            props.orderByProductFilterValue !== props.orderByDefaultValue
        ) {
            setProductLoadingState(true);

            const PK = props.subCategoryId ? `CATEGORY#${region}#${props.categoryId}#${props.subCategoryId}` : `CATEGORY#${region}#${props.categoryId}`;
            const SK = "";

            // QUERY HERE

            // setProductLoadingState(loading);
            // setProducts(data);
        }
    }, [categoryProducts]);

    if (categoryProductsLoadingState === true) {
        return (
            <p>Filtering Products...</p>
        );
    }

    if (productLoadingState === true) {
        return (
            <p>Loading Products...</p>
        );
    }

    if (
        products &&
        products.length > 0
    ) {

        <ol className="p-category__list">
            {
                products && products.map((product, index) => {
                    return (
                        <ProductListing
                            key={`p-category__list-item-${index}`}
                            containerClasses="p-category__list-item"
                            category={props.category}
                            subCategory={props.subCategory}
                            product={product}
                            languageRegion={languageRegion}
                        />
                    );

                })
            }
        </ol>;
    }

    return (
        <p>No content found...</p>
    );
};

